import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112')
];

export const server_loads = [2,6,8,10];

export const dictionary = {
		"/": [~13],
		"/auth/email": [14,[2]],
		"/auth/jointeam": [~15,[2,3]],
		"/auth/jointeam/email": [16,[2,3]],
		"/auth/jointeam/email/account": [17,[2,3]],
		"/auth/jointeam/email/confirmation": [18,[2,3]],
		"/auth/jointeam/email/reenter": [19,[2,3]],
		"/auth/jointeam/error": [20,[2,3]],
		"/auth/jointeam/wallet": [21,[2,3]],
		"/auth/jointeam/wallet/account": [22,[2,3]],
		"/auth/jointeam/wallet/confirmation": [23,[2,3]],
		"/auth/jointeam/wallet/email": [24,[2,3]],
		"/auth/jointeam/wallet/metamask": [25,[2,3]],
		"/auth/jointeam/wallet/reenter": [26,[2,3]],
		"/auth/jointeam/wallet/walletconnect": [27,[2,3]],
		"/auth/login": [28,[2,4]],
		"/auth/login/email": [29,[2,4]],
		"/auth/login/email/2fa": [30,[2,4]],
		"/auth/login/email/confirmation": [31,[2,4]],
		"/auth/login/email/reenter": [32,[2,4]],
		"/auth/login/error": [33,[2,4]],
		"/auth/login/wallet": [34,[2,4]],
		"/auth/login/wallet/2fa": [35,[2,4]],
		"/auth/login/wallet/metamask": [36,[2,4]],
		"/auth/login/wallet/walletconnect": [37,[2,4]],
		"/auth/signup": [38,[2,5]],
		"/auth/signup/email": [39,[2,5]],
		"/auth/signup/email/account": [40,[2,5]],
		"/auth/signup/email/confirmation": [41,[2,5]],
		"/auth/signup/email/connect": [42,[2,5]],
		"/auth/signup/email/invite": [43,[2,5]],
		"/auth/signup/email/reenter": [44,[2,5]],
		"/auth/signup/email/referral": [45,[2,5]],
		"/auth/signup/error": [46,[2,5]],
		"/auth/signup/wallet": [47,[2,5]],
		"/auth/signup/wallet/account": [48,[2,5]],
		"/auth/signup/wallet/confirmation": [49,[2,5]],
		"/auth/signup/wallet/connect": [50,[2,5]],
		"/auth/signup/wallet/email": [51,[2,5]],
		"/auth/signup/wallet/invite": [52,[2,5]],
		"/auth/signup/wallet/metamask": [53,[2,5]],
		"/auth/signup/wallet/reenter": [54,[2,5]],
		"/auth/signup/wallet/referral": [55,[2,5]],
		"/auth/signup/wallet/register": [56,[2,5]],
		"/auth/signup/wallet/walletconnect": [57,[2,5]],
		"/csat/[id=bson]": [58],
		"/dashboard": [59,[6]],
		"/dashboard/admin": [~60,[6,7]],
		"/dashboard/admin/ai/content": [61,[6,7]],
		"/dashboard/admin/ai/discord-training": [62,[6,7]],
		"/dashboard/admin/ai/scripted-responses": [63,[6,7]],
		"/dashboard/admin/ai/settings": [64,[6,7]],
		"/dashboard/admin/ai/sources": [65,[6,7]],
		"/dashboard/admin/api/keys": [66,[6,7]],
		"/dashboard/admin/api/keys/create": [67,[6,7]],
		"/dashboard/admin/api/webhooks": [68,[6,7]],
		"/dashboard/admin/api/webhooks/edit": [69,[6,7]],
		"/dashboard/admin/attributes": [70,[6,7]],
		"/dashboard/admin/automations/tickets": [71,[6,7]],
		"/dashboard/admin/automations/tickets/edit": [72,[6,7]],
		"/dashboard/admin/billing": [73,[6,7]],
		"/dashboard/admin/categories": [74,[6,7]],
		"/dashboard/admin/chatbot": [75,[6,7]],
		"/dashboard/admin/feedback": [76,[6,7]],
		"/dashboard/admin/general": [77,[6,7]],
		"/dashboard/admin/hook-notifications": [78,[6,7]],
		"/dashboard/admin/hook-notifications/discord": [79,[6,7]],
		"/dashboard/admin/hook-notifications/slack": [80,[6,7]],
		"/dashboard/admin/integrations": [81,[6,7]],
		"/dashboard/admin/integrations/discord": [82,[6,7,8]],
		"/dashboard/admin/integrations/discord/channel": [83,[6,7,8]],
		"/dashboard/admin/integrations/discord/panel": [84,[6,7,8]],
		"/dashboard/admin/integrations/email": [85,[6,7]],
		"/dashboard/admin/integrations/new": [86,[6,7]],
		"/dashboard/admin/integrations/slack/redirect": [~87,[6,7]],
		"/dashboard/admin/integrations/telegram": [88,[6,7,9]],
		"/dashboard/admin/integrations/web-chat": [92],
		"/dashboard/admin/integrations/web": [89,[6,7,10]],
		"/dashboard/admin/integrations/web/panel": [90,[6,7,10]],
		"/dashboard/admin/integrations/web/validation": [91,[6,7,10]],
		"/dashboard/admin/notifications": [93,[6,7]],
		"/dashboard/admin/plans": [94,[6,7]],
		"/dashboard/admin/profile": [95,[6,7]],
		"/dashboard/admin/tags": [96,[6,7]],
		"/dashboard/admin/team": [97,[6,7]],
		"/dashboard/admin/team/invite": [98,[6,7]],
		"/dashboard/admin/template-answer": [99,[6,7]],
		"/dashboard/admin/template-answer/new": [101,[6,7]],
		"/dashboard/admin/template-answer/[slug]": [100,[6,7]],
		"/dashboard/admin/ticket-settings": [102,[6,7]],
		"/dashboard/analytics": [~103,[6,11]],
		"/dashboard/analytics/performance": [104,[6,11]],
		"/dashboard/analytics/tags": [105,[6,11]],
		"/dashboard/analytics/tickets": [106,[6,11]],
		"/dashboard/analytics/volumes": [107,[6,11]],
		"/dashboard/telegram-group": [108,[6,12]],
		"/dashboard/ticket": [109,[6]],
		"/dashboard/ticket/[id=bson]": [~110,[6]],
		"/health": [111],
		"/socket.io": [~112]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';